import * as React from 'react'
import { graphql } from 'gatsby'
import { nanoid } from 'nanoid'
import Headings from '../components/Common/Headings'
import ColoredHeading from '../components/Common/ColoredHeading'
import CardBlog from '../components/Common/Card/CardBlog'
import Button from '../components/Common/Button'
import Seo from '../components/Seo'
import { useContent } from '../hooks/useContent'
import { getThumbnail } from '../helpers/thumbnail'

const Blog = ({ data }) => {
  const content = useContent(data)

  const [blogs, setBlogs] = React.useState(content.data.slice(0, 6))
  const [counter, setCounter] = React.useState(1)

  const getMoreBlogs = () => {
    const firstItem = counter * 6
    setBlogs(prev => [...prev, ...content.data.slice(firstItem, firstItem + 6)])
    setCounter(prev => prev + 1)
  }

  return (
    <>
      <Seo title={content.title} slug={'/blog'} />
      <div className="w-full mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          <div className="lg:col-span-8 lg:col-start-3 text-center grid gap-4 lg:gap-8">
            <Headings>
              <ColoredHeading text={content.heading} />
            </Headings>

            <p className="typo-medium-16 text-gray">{content.subHeading}</p>
          </div>
        </div>

        {/* Blog List */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-5 lg:gap-10 mt-6 lg:mt-10">
          {blogs?.map(
            ({ category, createdDate, title, tags, url, thumbnail }) => (
              <CardBlog
                key={nanoid()}
                category={category}
                createdDate={createdDate}
                title={title}
                tags={tags}
                url={url}
                thumbnail={getThumbnail(content, thumbnail)}
              />
            )
          )}
        </div>
        <div className="flex justify-center items-center mt-10">
          {content.data.length > blogs?.length && (
            <Button text="Show More" onClick={getMoreBlogs} />
          )}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($content: String = "blog") {
    allMarkdownRemark(filter: { frontmatter: { content: { eq: $content } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            heading
            subHeading
            data {
              title
              category
              tags
              thumbnail
              url
              createdDate
            }
          }
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "images/blogs" } }) {
      edges {
        node {
          relativePath
          name
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
              breakpoints: [640, 768, 1024, 1280]
            )
          }
        }
      }
    }
  }
`

export default Blog
